var hostname = window.location.hostname;
var isProd = hostname === "www.supersnail.vn";
// var disable = isProd ? false : true;
window.__bl = {
  config: {
    pid: "egrrccpv71@732032882f04ab5",
    appType: "web",
    imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    disabled: false,
    environment: isProd ? "prod" : "local",
    page: window.pageName,
  },
};
